define("ember-light-table/-private/global-options", ["exports", "@ember/polyfills", "ember-get-config"], function (_exports, _polyfills, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.mergeOptionsWithGlobals = mergeOptionsWithGlobals;
  // eslint-disable-next-line ember-suave/no-direct-property-access
  var globalOptions = _emberGetConfig.default['ember-light-table'] || {};
  var _default = _exports.default = globalOptions;
  function mergeOptionsWithGlobals(options) {
    return (0, _polyfills.assign)({}, globalOptions, options);
  }
});