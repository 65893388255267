define("ember-power-select-typeahead/templates/components/power-select-typeahead/trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "9qByjRRm",
    "block": "[[[10,\"input\"],[15,2,[36,0]],[14,\"role\",\"combobox\"],[15,1,[29,[\"ember-power-select-typeahead-input-\",[33,1,[\"uniqueId\"]]]]],[14,0,\"ember-power-select-typeahead-input ember-power-select-search-input\"],[14,\"autocomplete\",\"off\"],[14,\"autocorrect\",\"off\"],[14,\"autocapitalize\",\"off\"],[14,\"spellcheck\",\"false\"],[15,\"placeholder\",[36,2]],[15,\"oninput\",[36,3]],[15,\"onfocus\",[36,4]],[15,\"onblur\",[36,5]],[15,\"disabled\",[33,1,[\"disabled\"]]],[15,\"onkeydown\",[28,[37,6],[[30,0],\"handleKeydown\"],null]],[15,\"onmousedown\",[28,[37,6],[[30,0],\"stopPropagation\"],null]],[14,4,\"search\"],[12],[13],[1,\"\\n\"],[41,[33,1,[\"loading\"]],[[[1,\"  \"],[10,1],[14,0,\"ember-power-select-typeahead-loading-indicator\"],[12],[13],[1,\"\\n\"]],[]],null]],[],false,[\"text\",\"select\",\"placeholder\",\"onInput\",\"onFocus\",\"onBlur\",\"action\",\"if\"]]",
    "moduleName": "ember-power-select-typeahead/templates/components/power-select-typeahead/trigger.hbs",
    "isStrictMode": false
  });
});