define("ember-changeset-validations/utils/messages", ["exports", "ember-validators/messages", "@ember/polyfills", "@ember/string"], function (_exports, _messages, _polyfills, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Messages = (0, _polyfills.assign)({}, _messages.default);
  var _default = _exports.default = (0, _polyfills.assign)(Messages, {
    // Blank and present are flipped in ember-validators. Need to flip them back here
    blank: _messages.default.present,
    present: _messages.default.blank,
    getDescriptionFor: function getDescriptionFor() {
      var key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      return (0, _string.capitalize)((0, _string.dasherize)(key).split(/[_-]/g).join(' '));
    }
  });
});