define("ember-changeset/helpers/changeset", ["exports", "@ember/component/helper", "ember-changeset", "ember-changeset/utils/validator-lookup", "ember-changeset/utils/is-changeset", "ember-changeset/utils/is-promise", "ember-changeset/utils/is-object"], function (_exports, _helper, _emberChangeset, _validatorLookup, _isChangeset, _isPromise, _isObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changeset = changeset;
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  function changeset(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      obj = _ref2[0],
      validations = _ref2[1];
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    if ((0, _isChangeset.default)(obj)) {
      return obj;
    }
    if ((0, _isObject.default)(validations)) {
      if ((0, _isPromise.default)(obj)) {
        return obj.then(function (resolved) {
          return new _emberChangeset.default(resolved, (0, _validatorLookup.default)(validations), validations, options);
        });
      }
      return new _emberChangeset.default(obj, (0, _validatorLookup.default)(validations), validations, options);
    }
    if ((0, _isPromise.default)(obj)) {
      return Promise.resolve(obj).then(function (resolved) {
        return new _emberChangeset.default(resolved, validations, {}, options);
      });
    }
    return new _emberChangeset.default(obj, validations, {}, options);
  }
  var _default = _exports.default = (0, _helper.helper)(changeset);
});