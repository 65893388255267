define("ember-keyboard/mixins/activate-keyboard-on-focus", ["exports", "@ember/object/mixin", "@ember/object/evented", "@ember/object"], function (_exports, _mixin, _evented, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _mixin.default.create({
    activateKeyboardWhenFocused: (0, _evented.on)('click', 'focusIn', function () {
      (0, _object.set)(this, 'keyboardActivated', true);
    }),
    deactivateKeyboardWhenFocusOut: (0, _evented.on)('focusOut', function () {
      (0, _object.set)(this, 'keyboardActivated', false);
    })
  });
});