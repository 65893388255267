define("ember-keyboard/fixtures/code-maps/mac-safari-and-chrome", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    93: 'OSRight',
    124: 'PrintScreen',
    125: 'ScrollLock',
    126: 'Pause',
    12: 'NumLock',
    188: 'NumpadComma',
    190: 'NumpadComma',
    187: 'NumpadEqual'
  };
});