define('ember-css-modules/mixins/component-mixin', ['exports', '@ember/string'], function (exports, _string) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Mixin.create({
    localClassNames: null,
    localClassNameBindings: null,

    concatenatedProperties: ['localClassNames', 'localClassNameBindings'],

    init: function init() {
      this._super();

      if (this.tagName === '') return;

      this.classNameBindings = [].concat(_toConsumableArray(this.classNameBindings), _toConsumableArray(localClassNames(this)), [LOCAL_CLASS_NAMES_CP]);

      if (this.localClassNameBindings.length) {
        var value = localClassNamesCP(this.localClassNameBindings, this.get('__styles__'));
        Ember.defineProperty(this, LOCAL_CLASS_NAMES_CP, value);
      }
    },


    __styles__: Ember.computed(function () {
      // If styles is an explicitly set hash, defer to it. Otherwise, use the resolver.
      if (this.styles && Object.getPrototypeOf(this.styles) === Object.prototype) {
        return this.styles;
      }

      var key = this._debugContainerKey;
      if (!key) {
        return;
      }

      return Ember.getOwner(this).resolveRegistration('styles:components/' + key.substring(key.indexOf(':') + 1));
    })
  });


  var LOCAL_CLASS_NAMES_CP = '__ecm_local_class_names__';

  function localClassNames(component) {
    return component.localClassNames.map(function (className) {
      return '__styles__.' + className;
    });
  }

  function localClassNamesCP(localClassNameBindings, styles) {
    var _Ember;

    var bindings = localClassNameBindings.map(function (binding) {
      var _binding$split = binding.split(':'),
          _binding$split2 = _slicedToArray(_binding$split, 3),
          property = _binding$split2[0],
          trueStyle = _binding$split2[1],
          falseStyle = _binding$split2[2];

      var trueClasses = styles[trueStyle || (0, _string.dasherize)(property)] || '';
      var falseClasses = styles[falseStyle] || '';
      var isBoolean = !!trueStyle;
      return { property: property, trueClasses: trueClasses, falseClasses: falseClasses, isBoolean: isBoolean };
    });

    return (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(bindings.map(function (binding) {
      return binding.property;
    })).concat([function () {
      var _this = this;

      var classes = [];

      bindings.forEach(function (binding) {
        var value = _this.get(binding.property);
        if (binding.isBoolean || typeof value !== 'string') {
          classes.push(value ? binding.trueClasses : binding.falseClasses);
        } else {
          classes.push(value.split(/\s+/).map(function (key) {
            return styles[key];
          }).join(' '));
        }
      });

      return classes.join(' ');
    }]));
  }
});