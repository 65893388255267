define("ember-keyboard/fixtures/code-maps/gecko/mac", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    224: 'OSLeft',
    12: 'NumLock',
    108: 'NumpadComma'
  };
});