define("ember-keyboard/utils/trigger-event", ["exports", "@ember/polyfills", "ember-keyboard/utils/get-cmd-key", "ember-keyboard", "ember-keyboard/fixtures/modifiers-array"], function (_exports, _polyfills, _getCmdKey, _emberKeyboard, _modifiersArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.triggerKeyUp = _exports.triggerKeyPress = _exports.triggerKeyDown = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var triggerKeyEvent = function triggerKeyEvent(eventType, rawCode, element) {
    var event = new Event(eventType);
    var parts = rawCode.split('+');
    var _parts$filter = parts.filter(function (part) {
        return !_modifiersArray.default.includes(part);
      }),
      _parts$filter2 = _slicedToArray(_parts$filter, 1),
      code = _parts$filter2[0];
    var modifiers = parts.filter(function (part) {
      return part !== code;
    });
    var properties = modifiers.reduce(function (properties, modifier) {
      modifier = modifier === 'cmd' ? (0, _getCmdKey.default)() : modifier;
      properties["".concat(modifier, "Key")] = true;
      return properties;
    }, {});
    (0, _polyfills.assign)(event, {
      code: code,
      keyCode: (0, _emberKeyboard.getKeyCode)(code)
    }, properties);
    (element || document).dispatchEvent(event);
  };
  var triggerKeyDown = _exports.triggerKeyDown = function triggerKeyDown(code, element) {
    triggerKeyEvent('keydown', code, element);
  };
  var triggerKeyPress = _exports.triggerKeyPress = function triggerKeyPress(code, element) {
    triggerKeyEvent('keypress', code, element);
  };
  var triggerKeyUp = _exports.triggerKeyUp = function triggerKeyUp(code, element) {
    triggerKeyEvent('keyup', code, element);
  };
});