define("ember-text-overflow/templates/components/text-overflow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "d7r7R8nG",
    "block": "[[[10,0],[15,0,[29,[\"text-overflow-overlay \",[52,[30,1],\"align-right\"],\" \",[52,[51,[33,2]],\"text-overlay-hidden\"]]]],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"text-overflow-container\"],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"@alignRight\",\"&default\"],false,[\"if\",\"unless\",\"isOverlayVisible\",\"yield\"]]",
    "moduleName": "ember-text-overflow/templates/components/text-overflow.hbs",
    "isStrictMode": false
  });
});