define('ember-animatable/utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.animate = animate;

  var TRANSITION_END_PREFIXES = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';

  function animate(target, animationType) {
    var deferred = Ember.RSVP.defer();
    Ember.run.scheduleOnce('afterRender', function () {
      var classes = 'animated ' + animationType;
      Ember.$(target).one(TRANSITION_END_PREFIXES, Ember.run.bind(this, function (e) {
        Ember.$(e.currentTarget).removeClass(classes);
        deferred.resolve();
      })).addClass(classes);
    });
    return deferred.promise;
  }
});