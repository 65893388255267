define('ember-animatable/mixins/animatable', ['exports', 'ember-animatable/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    animate: function animate(animationType, target) {
      return (0, _utils.animate)(this.$(target), animationType);
    }
  });
});