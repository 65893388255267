define("ember-light-table/mixins/table-header", ["exports", "@ember/object/mixin", "@ember/object", "@ember/utils", "@ember/debug", "@ember/service", "ember-light-table/utils/css-styleify"], function (_exports, _mixin, _object, _utils, _debug, _service, _cssStyleify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module Light Table
   */
  /**
   * @class TableHeaderMixin
   * @extends Ember.Mixin
   * @private
   */
  var _default = _exports.default = _mixin.default.create({
    attributeBindings: ['style'],
    scrollbarThickness: (0, _service.inject)(),
    /**
     * @property table
     * @type {Table}
     * @private
     */
    table: null,
    /**
     * @property sharedOptions
     * @type {Object}
     * @private
     */
    sharedOptions: null,
    /**
     * @property tableActions
     * @type {Object}
     */
    tableActions: null,
    /**
     * @property extra
     * @type {Object}
     */
    extra: null,
    /**
     * @property fixed
     * @type {Boolean}
     * @default false
     */
    fixed: false,
    /**
     * @property sortOnClick
     * @type {Boolean}
     * @default true
     */
    sortOnClick: true,
    /**
     * @property multiColumnSort
     * @type {Boolean}
     * @default false
     */
    multiColumnSort: false,
    /**
     * Resize all cells in the column instead of just the header / footer
     *
     * @property resizeOnDrag
     * @type {Boolean}
     * @default false
     */
    resizeOnDrag: false,
    /**
     * CSS classes to be applied to an `<i class="lt-sort-icon"></i>` tag that is
     * inserted into the column's `<th>` element when the column is sortable but
     * not yet sorted.
     *
     * For instance, if you have installed `ember-font-awesome` or include the
     * `font-awesome` assets manually (e.g. via a CDN), you can set
     * `iconSortable` to `'fa fa-sort'`, which would yield this markup:
     * `<i class="lt-sort-icon fa fa-sort"></i>`
     *
     * @property iconSortable
     * @type {String}
     * @default ''
     */
    iconSortable: '',
    /**
     * See `iconSortable`.  CSS classes to apply to `<i class="lt-sort-icon"></i>`
     * when the column is sorted ascending.
     *
     * @property iconAscending
     * @type {String}
     * @default ''
     */
    iconAscending: '',
    /**
     * See `iconSortable`.  CSS classes to apply to `<i class="lt-sort-icon"></i>`
     * when the column is sorted descending.
     *
     * @property iconDescending
     * @type {String}
     * @default ''
     */
    iconDescending: '',
    /**
     * Custom sorting component name to use instead of the default `<i class="lt-sort-icon"></i>` template.
     * See `iconSortable`, `iconAsending`, or `iconDescending`.
     * @property iconComponent
     * @type {String}
     * @default false
     */
    iconComponent: null,
    /**
     * ID of main table component. Used to generate divs for ember-wormhole
     * @type {String}
     */
    tableId: null,
    renderInPlace: _object.computed.oneWay('fixed'),
    columnGroups: _object.computed.readOnly('table.visibleColumnGroups'),
    subColumns: _object.computed.readOnly('table.visibleSubColumns'),
    columns: _object.computed.readOnly('table.visibleColumns'),
    sortIcons: (0, _object.computed)('iconSortable', 'iconAscending', 'iconDescending', 'iconComponent', function () {
      return this.getProperties(['iconSortable', 'iconAscending', 'iconDescending', 'iconComponent']);
    }).readOnly(),
    style: (0, _object.computed)('sharedOptions.occlusion', function () {
      if (this.get('sharedOptions.occlusion')) {
        var scrollbarThickness = this.get('scrollbarThickness.thickness');
        return (0, _cssStyleify.default)({
          paddingRight: "".concat(scrollbarThickness, "px")
        });
      }
    }).readOnly(),
    init: function init() {
      this._super.apply(this, arguments);
      var fixed = this.get('fixed');
      var sharedOptionsFixedPath = "sharedOptions.".concat(this.get('sharedOptionsFixedKey'));
      (0, _object.trySet)(this, sharedOptionsFixedPath, fixed);
      var height = this.get('sharedOptions.height');
      (true && (0, _debug.warn)('You did not set a `height` attribute for your table, but marked a header or footer to be fixed. This means that you have to set the table height via CSS. For more information please refer to:  https://github.com/offirgolan/ember-light-table/issues/446', !fixed || fixed && !(0, _utils.isEmpty)(height), {
        id: 'ember-light-table.height-attribute'
      }));
    },
    actions: {
      /**
       * onColumnClick action. Handles column sorting.
       *
       * @event onColumnClick
       * @param  {Column} column The column that was clicked
       * @param  {Event} event The click event
       */
      onColumnClick: function onColumnClick(column) {
        if (column.sortable && this.get('sortOnClick')) {
          if (column.sorted) {
            column.toggleProperty('ascending');
          } else {
            if (!this.get('multiColumnSort')) {
              this.get('table.sortedColumns').setEach('sorted', false);
            }
            column.set('sorted', true);
          }
        }
        this.onColumnClick && this.onColumnClick.apply(this, arguments);
      },
      /**
       * onColumnDoubleClick action.
       *
       * @event onColumnDoubleClick
       * @param  {Column} column The column that was clicked
       * @param  {Event} event   The click event
       */
      onColumnDoubleClick: function onColumnDoubleClick() {
        this.onColumnDoubleClick && this.onColumnDoubleClick.apply(this, arguments);
      } /* column */,
      /**
       * onColumnResized action.
       *
       * @event onColumnResized
       * @param  {Column} column The column that was resized
       * @param  {String} width  The final width of the column
       */
      onColumnResized: function onColumnResized() {
        this.onColumnResized && this.onColumnResized.apply(this, arguments);
      } /* column, width */,
      /**
       * onColumnDrag action.
       *
       * @event onColumnDrag
       * @param  {Column} column The column that is being dragged
       */
      onColumnDrag: function onColumnDrag() {
        this.onColumnDrag && this.onColumnDrag.apply(this, arguments);
      } /* column */,
      /**
       * onColumnDrop action.
       *
       * @event onColumnDrop
       * @param  {Column} column The column that was dropped
       * @param  {Boolean} isSuccess The column was successfully dropped and sorted
       */
      onColumnDrop: function onColumnDrop() {
        this.onColumnDrop && this.onColumnDrop.apply(this, arguments);
      } /* column, isSuccess */
    }
  });
});