define("ember-composable-helpers/helpers/without", ["exports", "@ember/array", "@ember/object", "ember-composable-helpers/-private/create-needle-haystack-helper", "ember-composable-helpers/utils/includes"], function (_exports, _array, _object, _createNeedleHaystackHelper, _includes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.without = without;
  function contains(needle, haystack) {
    return (0, _includes.default)((0, _array.A)(haystack), needle);
  }
  function without(needle, haystack) {
    if (!(0, _array.isArray)(haystack)) {
      return false;
    }
    if ((0, _array.isArray)(needle) && (0, _object.get)(needle, 'length')) {
      return haystack.reduce(function (acc, val) {
        return contains(val, needle) ? acc : acc.concat(val);
      }, []);
    }
    return (0, _array.A)(haystack).without(needle);
  }
  var _default = _exports.default = (0, _createNeedleHaystackHelper.default)(without);
});