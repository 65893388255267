define("ember-basic-dropdown/templates/components/basic-dropdown-optional-tag", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "lX6h/YZy",
    "block": "[[[41,[30,1],[[[44,[[50,[28,[37,3],[[28,[37,4],[[30,1]],null]],null],0,null,[[\"tagName\"],[[30,1]]]]],[[[1,\"    \"],[8,[30,2],[[17,3]],null,[[\"default\"],[[[[1,\"\\n      \"],[18,4,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2]]]],[]],[[[1,\"  \"],[11,0],[17,3],[12],[1,\"\\n    \"],[18,4,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@htmlTag\",\"Element\",\"&attrs\",\"&default\"],false,[\"if\",\"let\",\"component\",\"ensure-safe-component\",\"-element\",\"yield\"]]",
    "moduleName": "ember-basic-dropdown/templates/components/basic-dropdown-optional-tag.hbs",
    "isStrictMode": false
  });
});