define("ember-composable-helpers/-private/create-multi-array-helper", ["exports", "@ember/object", "@ember/array", "@ember/component/helper", "@ember/object/internals", "@ember/utils"], function (_exports, _object, _array, _helper, _internals, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var idForArray = function idForArray(array) {
    return "__array-".concat((0, _internals.guidFor)(array));
  };
  function _default(multiArrayComputed) {
    return _helper.default.extend({
      compute: function compute(_ref) {
        var _ref2 = _toArray(_ref),
          arrays = _ref2.slice(0);
        (0, _object.set)(this, 'arrays', arrays.map(function (obj) {
          if ((0, _array.isArray)(obj)) {
            return (0, _array.A)(obj);
          }
          return obj;
        }));
        return (0, _object.get)(this, 'content');
      },
      valuesDidChange: (0, _object.observer)('arrays.[]', function () {
        this._recomputeArrayKeys();
        var arrays = (0, _object.get)(this, 'arrays');
        var arrayKeys = (0, _object.get)(this, 'arrayKeys');
        if ((0, _utils.isEmpty)(arrays)) {
          (0, _object.defineProperty)(this, 'content', []);
          return;
        }
        (0, _object.defineProperty)(this, 'content', multiArrayComputed.apply(void 0, _toConsumableArray(arrayKeys)));
      }),
      contentDidChange: (0, _object.observer)('content.[]', function () {
        this.recompute();
      }),
      _recomputeArrayKeys: function _recomputeArrayKeys() {
        var _this = this;
        var arrays = (0, _object.get)(this, 'arrays');
        var oldArrayKeys = (0, _object.get)(this, 'arrayKeys') || [];
        var newArrayKeys = arrays.map(idForArray);
        var keysToRemove = oldArrayKeys.filter(function (key) {
          return newArrayKeys.indexOf(key) === -1;
        });
        keysToRemove.forEach(function (key) {
          return (0, _object.set)(_this, key, null);
        });
        arrays.forEach(function (array) {
          return (0, _object.set)(_this, idForArray(array), array);
        });
        (0, _object.set)(this, 'arrayKeys', newArrayKeys);
      }
    });
  }
});