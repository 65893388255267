define("ember-composable-helpers/helpers/find-by", ["exports", "@ember/object", "@ember/component/helper", "@ember/array", "@ember/utils"], function (_exports, _object, _helper, _array, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var _default = _exports.default = _helper.default.extend({
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 3),
        byPath = _ref2[0],
        value = _ref2[1],
        array = _ref2[2];
      (0, _object.set)(this, 'array', array);
      (0, _object.set)(this, 'byPath', byPath);
      (0, _object.set)(this, 'value', value);
      return (0, _object.get)(this, 'content');
    },
    byPathDidChange: (0, _object.observer)('byPath', function () {
      var byPath = (0, _object.get)(this, 'byPath');
      if ((0, _utils.isEmpty)(byPath)) {
        (0, _object.defineProperty)(this, 'content', []);
        return;
      }
      (0, _object.defineProperty)(this, 'content', (0, _object.computed)("array.@each.".concat(byPath), 'value', function () {
        var array = (0, _object.get)(this, 'array');
        var value = (0, _object.get)(this, 'value');
        return (0, _array.A)(array).findBy(byPath, value);
      }));
    }),
    contentDidChange: (0, _object.observer)('content', function () {
      this.recompute();
    })
  });
});