define("ember-filestack/utils/sanitize-transformations", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sanitizeTransformations;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function sanitizeTransformations(obj) {
    Object.keys(obj).forEach(function (key) {
      var value = obj[key];
      var type = _typeof(value);
      if (type === 'object' && value !== null) {
        sanitizeTransformations(value);
        if (Object.keys(value).length === 0) {
          delete obj[key];
        }
      } else if ((0, _utils.isNone)(value)) {
        delete obj[key];
      }
    });
  }
});