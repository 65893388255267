define('ember-animatable/index', ['exports', 'ember-animatable/mixins/animatable', 'ember-animatable/utils'], function (exports, _animatable, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _animatable.default;
    }
  });
  Object.defineProperty(exports, 'animate', {
    enumerable: true,
    get: function () {
      return _utils.animate;
    }
  });
});