define("ember-keyboard/services/keyboard", ["exports", "@ember/service", "@ember/array", "@ember/application", "@ember/object", "@ember/object/computed", "@ember/runloop", "ember-keyboard/listeners/key-events", "ember-keyboard/utils/handle-key-event"], function (_exports, _service, _array, _application, _object, _computed, _runloop, _keyEvents, _handleKeyEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _service.default.extend({
    isPropagationEnabled: false,
    registeredResponders: (0, _object.computed)(function () {
      return (0, _array.A)();
    }),
    activeResponders: (0, _computed.filterBy)('registeredResponders', 'keyboardActivated'),
    sortedRespondersSortDefinition: (0, _object.computed)('isPropagationEnabled', function () {
      return (0, _object.get)(this, 'isPropagationEnabled') ? ['keyboardPriority:desc'] : ['keyboardFirstResponder:desc', 'keyboardPriority:desc'];
    }),
    sortedResponders: (0, _computed.sort)('activeResponders', 'sortedRespondersSortDefinition'),
    firstResponders: (0, _computed.filterBy)('sortedResponders', 'keyboardFirstResponder'),
    normalResponders: (0, _computed.filter)('sortedResponders.@each.keyboardFirstResponder', function (responder) {
      return !(0, _object.get)(responder, 'keyboardFirstResponder');
    }),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      if (typeof FastBoot !== 'undefined') {
        return;
      }
      var config = (0, _application.getOwner)(this).resolveRegistration('config:environment') || {};
      var isPropagationEnabled = Boolean((0, _object.get)(config, 'emberKeyboard.propagation'));
      (0, _object.set)(this, 'isPropagationEnabled', isPropagationEnabled);
      this._boundRespond = (0, _runloop.bind)(this, this._respond);
      this._listeners = (0, _object.get)(config, 'emberKeyboard.listeners') || ['keyUp', 'keyDown', 'keyPress'];
      this._listeners = this._listeners.map(function (listener) {
        return listener.toLowerCase();
      });
      this._listeners.forEach(function (type) {
        document.addEventListener(type, _this._boundRespond);
      });
    },
    willDestroy: function willDestroy() {
      var _this2 = this;
      this._super.apply(this, arguments);
      if (typeof FastBoot !== 'undefined') {
        return;
      }
      this._listeners.forEach(function (type) {
        document.removeEventListener(type, _this2._boundRespond);
      });
    },
    _respond: function _respond(event) {
      var _this3 = this;
      (0, _runloop.run)(function () {
        if ((0, _object.get)(_this3, 'isPropagationEnabled')) {
          (0, _handleKeyEvent.handleKeyEventWithPropagation)(event, (0, _object.getProperties)(_this3, 'firstResponders', 'normalResponders'));
        } else {
          (0, _handleKeyEvent.handleKeyEventWithLaxPriorities)(event, (0, _object.get)(_this3, 'sortedResponders'));
        }
      });
    },
    register: function register(responder) {
      (0, _object.get)(this, 'registeredResponders').pushObject(responder);
    },
    unregister: function unregister(responder) {
      (0, _object.get)(this, 'registeredResponders').removeObject(responder);
    },
    keyDown: function keyDown() {
      return _keyEvents.keyDown.apply(void 0, arguments);
    },
    keyPress: function keyPress() {
      return _keyEvents.keyPress.apply(void 0, arguments);
    },
    keyUp: function keyUp() {
      return _keyEvents.keyUp.apply(void 0, arguments);
    }
  });
});