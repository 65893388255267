define("ember-composable-helpers/helpers/array", ["exports", "@ember/component/helper", "@ember/array"], function (_exports, _helper, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.array = array;
  _exports.default = void 0;
  function array() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    // slice params to avoid mutating the provided params
    return (0, _array.A)(params.slice());
  }
  var _default = _exports.default = (0, _helper.helper)(array);
});