define("ember-sortable/templates/components/sortable-handle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "L8QuJKK3",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-sortable/templates/components/sortable-handle.hbs",
    "isStrictMode": false
  });
});