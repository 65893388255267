define("ember-composable-helpers/helpers/contains", ["exports", "@ember/array", "@ember/object", "ember-composable-helpers/-private/create-needle-haystack-helper", "ember-composable-helpers/utils/includes"], function (_exports, _array, _object, _createNeedleHaystackHelper, _includes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contains = contains;
  _exports.default = void 0;
  function _contains(needle, haystack) {
    return (0, _includes.default)((0, _array.A)(haystack), needle);
  }
  function contains(needle, haystack) {
    if (!(0, _array.isArray)(haystack)) {
      return false;
    }
    if ((0, _array.isArray)(needle) && (0, _object.get)(needle, 'length')) {
      return needle.reduce(function (acc, val) {
        return acc && _contains(val, haystack);
      }, true);
    }
    return _contains(needle, haystack);
  }
  var _default = _exports.default = (0, _createNeedleHaystackHelper.default)(contains);
});