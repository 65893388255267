define("ember-sortable/components/sortable-handle", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * This component represents the handle of a `sortable-item`.
   */
  var _default = _exports.default = _component.default.extend({
    tabindex: 0,
    role: 'button',
    attributeBindings: ["dataSortableHandle:data-sortable-handle", "tabindex", "role"],
    dataSortableHandle: true
  });
});